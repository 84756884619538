import React, { Component, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { graphql, withApollo } from 'react-apollo';
import { useConfirm } from "material-ui-confirm";
import { GraphQLFormProvider } from 'react-form-helper'
import { ActivityIndicator, Button, DisplayFile, Grid, Modal } from '../generic'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router'
import { getAllGroups } from './GroupsList'
import TextField from '@material-ui/core/TextField'
import { ClientInformation, ClientDocuments, ClientNotes, ClientCommunications } from '../shared'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-apollo'
import BankAccount from '../bankAccount/BankAccount'
import fileDownload from 'js-file-download'

import GroupFuneralInfo from '../funeral/GroupFuneralInfo'
import GraphQLTable from '../generic/GraphQLTable'
import { DOCUMENT_TYPES } from '../../variables/documentTypes';
import WhatsAppChat from '../communication/WhatsAppChat';
import ProductCommunication from '../communication/ProductCommunication';
import { CREATE_NOTE } from '../shared/client/ClientNotes';
import PaymentHistory from '../credit-life/PaymentHistory';
import OutstandingRequirements from '../clearance-certificate/OutstandingRequirements';
import { API_URL } from '../../config';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 7
  }
}));

export const GET_GROUP = gql`
query ($id: String!) {
  group(id: $id)
  {
     id
     name
     roleHolders {
      clientId
      role
      idDocumentId
      selfieId
      signatureId
      client{
          id
          firstNames
          surname
      }  
    }
    products {
      id
      paymentDueDay
      debitOrderId
      outstandingRequirements
      paymentBankAccount {
        id
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderContactNumber
        accountHolderIdNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
      }
      payments {
        id
        paymentFor
        timestamp
        amount
        paymentType
      }
    }
  }
}
`

const GET_FUNERALS = (groupProductId) => gql`{funerals(filter: {groupProductId: "${groupProductId}"}){
  id
  policyNumber
  state
  client {
    firstNames
    surname
    idNumber
  }
}
}
`

const UPDATE_GROUP_PRODUCT = gql`
  mutation ($input: UpdateGroupProductInput!, $id: String!) {
    updateGroupProduct(id: $id, input: $input) {
        id
        paymentDueDay
        debitOrderId
        paymentBankAccount {
          id
          bankName
          branchCode
          accountNo
          ownAccount
          accountHolderName
          accountHolderContactNumber
          accountHolderIdNumber
          accountHolderEmail
          idNumberMatch
          nameMatch
          phoneMatch
          accountExists
          avsRawData
      }
    }
  }
`

const SEND_SIGNATURE_LINK = gql`
	mutation($groupId: String!, $clientId: String) {
		groupSignatureLink(groupId: $groupId, clientId: $clientId ) {
      id
		}
	}
`;

const UPDATE_ROLE_HOLDER = gql`
	mutation($id: String!, $input:UpdateGroupInput! ) {
		updateGroup(id: $id, input: $input) {
      id
    }
  }
`;

// const ADD_ROLEHOLDER = gql`
// mutation($id: String!, $input:UpdateGroupInput! ) {
//   updateGroup(id: $id, input: $input) {
//      id
//      name
//      roleHolders {
//       clientId
//       role
//       idDocumentId
//       selfieId
//       signatureId
//       client {
//         id
//         firstNames
//         surname
//       }
//     }
//     products {
//       id
//     }
//   }
// }`

const mapRoleHolders = (roleHolders, clientId, signatureId) => {
  return (roleHolders || []).map((roleHolder) => {
    if ((roleHolder.clientId || "").toString() === (clientId || "").toString()) {
      return {
        clientId: roleHolder.clientId,
        role: roleHolder.role,
        idDocumentId: roleHolder.idDocumentId,
        selfieId: roleHolder.selfieId,
        signatureId
      }
      // these return objects must be the same as the GroupRoleHolderInput
    }
    return {
      clientId: roleHolder.clientId,
      role: roleHolder.role,
      idDocumentId: roleHolder.idDocumentId,
      selfieId: roleHolder.selfieId,
      signatureId: roleHolder.signatureId
    }
  })
}


function FuneralMemberList({ groupProductId, history }) {
  const config = {
    query: GET_FUNERALS(groupProductId),
    dataset: (data) => data.funerals,
    fields: {
      'client.firstNames': { label: 'First Names' },
      'client.surname': { label: 'Surname' },
      'client.idNumber': {label: 'Id Number'},
      policyNumber: { label: 'Policy Number' },
      state: { label: 'State' }
    },
    defaultSorted: [{ id: 'policyNumber' }]
  }
  return <GraphQLTable config={config} onRowPress={(funeral) => history.push(`/funeral/${funeral.id}/edit`)} />
}

let Group = (props) => {
  const { mode, id, history } = props
  const { data, error, loading, client: apolloClient } = useQuery(GET_GROUP, { variables: { id } })
  const confirm = useConfirm();
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackText, setSnackText] = useState()
  const [showSpinner, setShowSpinner] = useState(false)
  // const [open, setOpen] = useState(false)


  if (loading) {
    return <ActivityIndicator />
  }

  const { group } = data || {}
  const { products } = group || {}
  const signatureRequirement = (products && products[0].outstandingRequirements || []).some(requirement => (requirement.toLowerCase()).includes('signature'))

  const updateGroupProduct = (id) => (input) => {
    apolloClient.mutate({
      mutation: UPDATE_GROUP_PRODUCT,
      variables: {
        id,
        input
      }    
    })
  }

  const groupProductMembersReport = async (groupProductId) => {
    setShowSpinner(true);
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`${API_URL}funeralGroupMembersReport/${groupProductId}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
    const data = await response.blob()
    fileDownload(data, `funeralGroupMembersReport.xlsx`)
    setShowSpinner(false);
  }

  const sendSignatureLink = (groupId, clientId) => {
    confirm({
      title: 'Send Signature Link via SMS',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Press 'OK' to go ahead with sending the link, press 'CANCEL' stop the operation.`
    }).then(() => {
      props.client
        .mutate({
          mutation: SEND_SIGNATURE_LINK,
          variables: {
            groupId,
            clientId
          }
        }).then(() => {
          setSnackSeverity('success')
          setSnackOpen(true);
          setSnackText('Signature SMS Reminder sent');
        })
    })
  }

  const removeRoleHolder = (groupId, roleHolders, roleHolderId) => {
    const remainingRoleHolders = (roleHolders || []).filter(roleHolder => (roleHolder.clientId || "").toString() !== (roleHolderId || "").toString())
    const removedRoleHolder = (roleHolders || []).find(roleHolder => (roleHolder.clientId || "").toString() === (roleHolderId || "").toString())
    confirm({
      title: 'Remove Role Holder',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Press 'OK' to go ahead with removing the Role Holder, press 'CANCEL' stop the operation.`
    }).then(() => {
      const associatedWith = [{ type:'Group', id: groupId,  }]
      const input = {text: `Role Holder (${removedRoleHolder.role}): ${removedRoleHolder.client.firstNames} ${removedRoleHolder.client.surname} removed`, associatedWith }
      props.client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
      updateRoleHolder(groupId, remainingRoleHolders)
    })
  }

  const updateRoleHolder = (groupId, roleHolders, clientId, fileId) => {
    props.client
      .mutate({
        mutation: UPDATE_ROLE_HOLDER,
        variables: {
          id: groupId,
          input: { roleHolders: mapRoleHolders(roleHolders, clientId, fileId) }
        }, refetchQueries: [{ query: GET_GROUP, variables: { id } }]
      }).then(() => {
        setSnackSeverity('success')
        setSnackOpen(true);
        setSnackText('Role Holder Updated');
      })
  }
  
  const groupId = id

  return (
    <div>
      <p />
        <Grid container justifyContent='flex-start' textAlign='center' margin='1px' spacing={2}>
        <Grid item xs={5} alignItems='center'>
        <h1>{data.group.name}</h1>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3} >
        <div style={{ paddingTop: 5, left: '48em' }} >
          <Button color="primary" onClick={() => history.push(`/groups/${groupId}/add`)} > Add Role Holder </Button>
        </div>
        </Grid>
      </Grid>
      {(data.group.products || []).map((product) => (
        <>
          <GroupFuneralInfo id={product.id} />
          <p/> &nbsp;
          <OutstandingRequirements outstandingRequirements={product.outstandingRequirements} />
          <p/> &nbsp;
          <PaymentHistory history={product.payments} />
          <p/> &nbsp;
          <ClientNotes groupProductId={product.id} productId={product.id} type={'GroupProduct'} />
          <p/> &nbsp;
          <BankAccount groupId={data.group.id} debitOrderId={product.debitOrderId} productId={product.id} productType={"GROUP_PRODUCT"} paymentDay={data.group.products[0].paymentDueDay} paymentBankAccount={product.paymentBankAccount} updateProduct={updateGroupProduct(product.id)} {...product} /> 
          <p/> &nbsp; 
          <ProductCommunication groupProductId={product.id} groupId={data.group.id} type={"GROUP_PRODUCT"} hideWhatsApp />
          <p/> &nbsp;
        </>
      ))}
      <break/>
      {(data.group.roleHolders || []).map((roleHolder) => (<>
        <ClientInformation key={roleHolder.clientId} title={roleHolder.role} id={roleHolder.clientId} history={history} />
        <>
        {signatureRequirement &&
          <Grid container className={classes.documents} spacing={1}>
          <Grid item>
            <p /> &nbsp;
            <Button color='secondary' onClick={() => sendSignatureLink(id, roleHolder.clientId)}> Send Signature Link to {roleHolder.role} </Button>
            <p/>
          </Grid>
          <Grid item>
            <p/>
            <DisplayFile documentType={'SIGNATURE'} noDelete={true} displayIfValid={true} onChange={(fileId) => updateRoleHolder(data.group.id, data.group.roleHolders, roleHolder.clientId, fileId)} description={'SIGNATURE'} />
          </Grid>
        </Grid>}
          <Grid item >
            <p /> &nbsp;
            <Button color='secondary' onClick={() => removeRoleHolder(id, data.group.roleHolders, roleHolder.clientId)}> Remove {roleHolder.role}</Button>
            <p /> &nbsp;
          </Grid>
          <p/>
          <ProductCommunication clientId={roleHolder.clientId} productId={data.group.products.id} type={"GROUP_PRODUCT"} whatsAppOnly={true} />
          <p /> &nbsp; 
          </>
      </>))}
      <br/>
      {(data.group.products || []).map((product) => (
        <>
          <ClientDocuments groupInfo={{groupId:data.group.id, groupProductId: product.id, roleHolders: data.group.roleHolders}} />
          <p/> &nbsp;
          <Grid container justifyContent='flex-start' textAlign='center' margin='1px' spacing={2}>
            <Grid item xs={5} alignItems='center'>
              <h2>Members</h2>
            </Grid>
            <Grid item xs={4}>
              {showSpinner && <ActivityIndicator center={false} />}
            </Grid>
            <Grid item xs={3} >
              <div style={{ paddingTop: 5, left: '48em' }} >
                <Button color="primary" onClick={() => groupProductMembersReport(product.id)} > Members Report </Button>
              </div>
            </Grid>
          </Grid>
          <FuneralMemberList groupProductId={product.id} history={history} />
          <p/> &nbsp;
        </>
      ))}
      <br/>
      <br />
      <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} >
          {snackText}
        </Alert>
      </Snackbar>
    </div>
  )
}

Group = withApollo(Group);
export default Group
